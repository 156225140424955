@import "../../variables.scss";

.logo {
  padding: $spacing-size-xxxl;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.client-picker {
  border-top: $spacing-size-xxxs solid #5b6b56;
  padding: 2rem 1rem;

  &__select.MuiInputBase-root {
    background-color: rgba(244, 249, 243, 0.1);
    color: $white;

    svg {
      right: 1rem;
      top: calc(50% - 0.3em);
      color: #649f56;
      transition: transform 100ms ease-in-out;
    }
  }
}

.navigation {
  display: flex;
  flex-direction: column;

  &__list {
    border-top: 1px solid #5b6b56;
    padding: $spacing-size-xxxl;
  }

  &__list-item {
    margin-bottom: $spacing-size-xxl;
  }

  &__link {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    font-weight: 700;
    color: #5b6b56;

    &--active {
      color: $white;

      .navigation__icon {
        color: $white;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: $spacing-size-xs;
        height: $spacing-size-xxxl;
        right: -$spacing-size-xxxl;
        background-color: $white;
        border-radius: $spacing-size-xxs;
      }
    }

    &:hover {
      color: $white;

      .navigation__icon {
        color: $white;
      }
    }
  }

  &__icon {
    margin-right: $spacing-size-lg;
    font-size: $spacing-size-xl;
  }
}
