// Colors
$text-primary: #283f22;

$white: #fff;
$black: #003965;

$primary: #649f56;
$secondary: #2b3674;

$error: #ff3a30;
$warning: #ffaf02;
$info: #608dff;
$success: #5dc637;

$greyText: #b9bcb8;
$lightGrey: #f2f6ff;
$darkGrey: #a3aed0;

// Spacing

$spacing-size-none: 0;
$spacing-size-xxxs: 0.0625rem; // 1px
$spacing-size-xxs: 0.125rem; // 2px
$spacing-size-xs: 0.25rem; // 4px
$spacing-size-sm: 0.5rem; // 8px
$spacing-size-md: 0.75rem; // 12px
$spacing-size-lg: 1rem; // 16px
$spacing-size-xl: 1.25rem; // 20px
$spacing-size-xxl: 1.5rem; // 24px
$spacing-size-xxxl: 2rem; // 32px

// Font Weight
$font-weight-xl: 700;
