@import "../../variables.scss";

.page {
  display: flex;
  flex-direction: row;
  background-color: #f6f6f5;

  &__sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 18.125rem;
    background: linear-gradient(
        180deg,
        #283f22 43.75%,
        rgba(40, 63, 34, 0.35) 100%
      ),
      url("./../../../public/assets/images/sidebarBg.png") center center
        no-repeat;
    background-size: cover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: calc(100% - 18.125rem);
    overflow-y: scroll;
    padding: 2rem 3rem 2rem 2rem;
    position: relative;
  }
}
